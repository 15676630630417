import React, { useEffect, useState } from "react"
import Layout from "../components/layout-2"
import { Amplify, API, Hub } from "aws-amplify"
import awsconfic from "../aws-exports"
import { withAuthenticator } from "@aws-amplify/ui-react"
import "@aws-amplify/ui-react/styles.css"
import { connectYnab, listPersons } from "../graphql/queries"
import { navigate } from "gatsby"
import LoadingAnimation from "../components/loading-animation"

Amplify.configure(awsconfic)

const OwnConnections = ({ signOut, user, _serverData, location }) => {
  const [code, setCode] = useState(undefined)
  const params = new URLSearchParams(location.search)
  const codeFromUrl = params.get("code")
  if (codeFromUrl != code) {
    setCode(codeFromUrl)
  }
  useEffect(() => {
    // fetch all the notes
    void updatePersonInfo(codeFromUrl)
  }, [code])

  useEffect(() => {
    Hub.listen("auth", ({ payload: { event } }) => {
      if (event === "signIn") {
        // use is signed in
        const personIfo = updatePersonInfo(true)
      }
    })
  })

  const updatePersonInfo = async codeFromUrl => {
    const person2 = await API.graphql({
      query: connectYnab,
      variables: {
        msg: JSON.stringify({
          ynabCode: codeFromUrl,
        }),
      },
    })
    navigate("/own-connections")
  }
  return (
    <Layout path="/own-connections" signOut={signOut}>
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
          <div className="text-center">
            <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
              Connecting to YNAB
            </p>
            <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">
              When connection is ready you will be redirected to connect bank to
              your bank(s).
            </p>
          </div>
        </div>
      </div>
      <main>
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          <LoadingAnimation />
        </div>
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8"></div>
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8"></div>
      </main>
    </Layout>
  )
}

export default withAuthenticator(OwnConnections)
